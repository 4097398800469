import { PropsWithChildren } from "react";
import { K33ButtonRed } from "../K33ButtonRed";
import {
  adminNavBarNavigationConfig,
  NavigationMode,
  openNavBarNavigationConfig,
  userNavBarNavigationConfig,
} from "./config";
import { usePathname } from "next/navigation";
import { signOut } from "next-auth/react";

export function K33NavBarMenu() {
  const pathname = usePathname();

  const navigationMode: NavigationMode =
    !pathname || pathname === "/" || pathname.startsWith("/auth")
      ? "OPEN"
      : pathname.startsWith("/admin")
      ? "ADMIN"
      : "USER";

  const navigationConfig =
    navigationMode === "OPEN"
      ? openNavBarNavigationConfig
      : navigationMode === "ADMIN"
      ? adminNavBarNavigationConfig
      : userNavBarNavigationConfig;

  const secondaryButton =
    navigationMode === "OPEN" ? (
      <NavBarButton name="Log in to Markets" href="https://markets.k33.com/" />
    ) : navigationMode === "ADMIN" ? (
      <NavBarButton name="User view" href="/loan" />
    ) : (
      <NavBarButton name="Profile" href="/profile" />
    );

  const primaryButton =
    navigationMode === "OPEN" ? (
      <K33ButtonRed href="https://k33.com/markets/open-account">
        Start Trading
      </K33ButtonRed>
    ) : (
      <K33ButtonRed onClick={() => signOut()}>Log out</K33ButtonRed>
    );

  return (
    <div
      id="nav-menu"
      className="hidden w-full flex-row items-center justify-between font-manrope font-medium lg:flex"
    >
      <div id="menu-buttons" className="flex flex-row items-center">
        {navigationConfig &&
          Object.entries(navigationConfig).map(([key, value]) => {
            return Array.isArray(value) ? (
              <NavBarButton name={key} key={key}>
                <NavBarButtonDropdown>
                  {value.map((config) => {
                    return (
                      <NavBarButtonDropdownButton
                        key={config.name}
                        px="px-[2.55rem]"
                        {...config}
                      />
                    );
                  })}
                </NavBarButtonDropdown>
              </NavBarButton>
            ) : (
              <NavBarButton name={key} href={value.href} key={key}/>
            );
          })}
      </div>

      <div id="function-buttons" className="flex flex-row items-center ">
        {secondaryButton}
        {primaryButton}
      </div>
    </div>
  );
}

function NavBarButton(
  props: { name: string } & PropsWithChildren & JSX.IntrinsicElements["a"]
) {
  const { name, ...aProps } = props;

  return (
    <div
      id="anchor-drop-down-container"
      className="group/drop-down relative flex flex-row items-center justify-center gap-5 px-1 text-center first:px-10 last:px-8"
    >
      <a
        {...aProps}
        id="anchor-name"
        className="group/drop-down-text flex cursor-pointer flex-row items-center gap-5 text-lg font-semibold text-[#f5f6fc] transition-colors duration-300 ease-in-out hover:stroke-[#a54242] hover:text-[#a54242]"
      >
        {name}
        {
          // animated arrow svg
          props.children && (
            <p
              id="arrow-svg-container"
              className="h-[26px] w-[16px] transition-transform duration-300 ease-in-out group-hover/drop-down:rotate-180"
            >
              <svg
                height={"100%"}
                width={"100%"}
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6.49991 7L12 1"
                  className="transition-colors duration-300 ease-in-out group-hover/drop-down-text:stroke-[#a54242]"
                  stroke="#f5f6fc"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </p>
          )
        }
      </a>

      {props.children}
    </div>
  );
}

function NavBarButtonDropdown(props: PropsWithChildren) {
  return (
    <div
      id="drop-down"
      className="pointer-events-none absolute top-0 left-0 mt-6 flex h-fit w-full flex-col items-center justify-center self-start group-hover/drop-down:pointer-events-auto"
    >
      <div className="h-10" />
      <div
        className="flex w-full origin-top-left scale-y-0 flex-col items-center justify-start gap-5 rounded-b-md bg-[#f5f6fc] py-10 
            transition-transform duration-300 ease-in-out group-hover/drop-down:flex group-hover/drop-down:scale-y-100  "
      >
        {" "}
        {props.children}
      </div>
    </div>
  );
}

function NavBarButtonDropdownButton(
  props: {
    name: string;
    px?: string;
    labelText?: string;
  } & JSX.IntrinsicElements["a"]
) {
  const { name, px, labelText, ...aProps } = props;

  const className = `relative flex w-full cursor-pointer items-start px-8 text-lg text-black transition-colors duration-300 ease-in-out hover:text-[#a54242] ${
    px ?? "px-8"
  }`;

  return (
    <a id="subanchor-name" {...aProps} className={className}>
      {name}
      {labelText && (
        <label className="absolute -top-1 right-[3.2rem] text-sm font-semibold text-[#a54242]">
          {labelText}
        </label>
      )}
    </a>
  );
}
