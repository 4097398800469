import { PropsWithChildren } from "react";
import { K33ButtonRed } from "../K33ButtonRed";
import { usePathname } from "next/navigation";
import {
  adminDropdownNavigationConfig,
  NavigationMode,
  openDropdownNavigationConfig,
  userDropdownNavigationConfig,
} from "./config";
import { signOut } from "next-auth/react";

export function K33DropdownMenu(props: { menuOpen: boolean }) {
  const { menuOpen } = props;

  const pathname = usePathname();

  const navigationMode: NavigationMode =
    !pathname || pathname === "/" || pathname.startsWith("/auth")
      ? "OPEN"
      : pathname.startsWith("/admin")
      ? "ADMIN"
      : "USER";

  const navigationConfig =
    navigationMode === "OPEN"
      ? openDropdownNavigationConfig
      : navigationMode === "ADMIN"
      ? adminDropdownNavigationConfig
      : userDropdownNavigationConfig;

  const secondaryButton =
    navigationMode === "OPEN" ? (
      <K33DropdownMenuButton href="https://k33.com/markets">
        Log in to Markets
      </K33DropdownMenuButton>
    ) : navigationMode === "ADMIN" ? (
      <K33DropdownMenuButton href="/loan">User view</K33DropdownMenuButton>
    ) : (
      <K33DropdownMenuButton href="/profile">Profile</K33DropdownMenuButton>
    );

  const primaryButton =
    navigationMode === "OPEN" ? (
      <K33ButtonRed href="https://k33.com/markets/open-account">
        Start Trading
      </K33ButtonRed>
    ) : (
      <K33ButtonRed onClick={() => signOut()}>Log out</K33ButtonRed>
    );

  return (
    <div
      style={{
        height: menuOpen ? "85rem" : "0%",
        transform: menuOpen ? "scaleY(100%)" : "scaleY(0%)",
        visibility: menuOpen ? "visible" : "hidden",
      }}
      id="nav-bar-main-menu-dropdown-container"
      className="absolute top-[100%] flex w-full 
     origin-top-left border-b-[0.3rem] border-white bg-[#1e1e24] font-manrope transition-all duration-300 ease-in-out lg:hidden"
    >
      <div
        id="nav-bar-main-menu-dropdown-container-padding"
        className="flex w-full max-w-5xl flex-col items-start gap-10 px-4 pt-12 sm:px-7 lg:max-w-6xl lg:justify-start xl:px-0"
      >
        {navigationConfig &&
          Object.entries(navigationConfig).map(([key, value], i) => {
            return (
              Array.isArray(value) && (
                <K33DropdownMenuCol key={i}>
                  <K33DropdownMenuTitle>{key}</K33DropdownMenuTitle>

                  {value.map((config, j) => {
                    return (
                      <K33DropdownMenuButton href={config.href} key={j}>
                        {config.name}
                      </K33DropdownMenuButton>
                    );
                  })}
                </K33DropdownMenuCol>
              )
            );
          })}
        <K33DropdownMenuCol>
          <K33DropdownMenuTitle>Account</K33DropdownMenuTitle>
          {secondaryButton}
          <div />
          {primaryButton}
        </K33DropdownMenuCol>
      </div>
    </div>
  );
}

function K33DropdownMenuCol(props: {} & PropsWithChildren) {
  return <div className="flex flex-col gap-3">{props.children}</div>;
}

function K33DropdownMenuTitle(props: {} & PropsWithChildren) {
  return (
    <h3 className="flex flex-col gap-3 text-base text-[#82808a]">
      {props.children}
    </h3>
  );
}

function K33DropdownMenuButton(
  props: { labelText?: string } & JSX.IntrinsicElements["a"] & PropsWithChildren
) {
  const { labelText, ...aProps } = props;

  return (
    <a
      className="relative text-2xl font-medium text-[#f5f6fc] transition-colors duration-300 ease-in-out hover:text-[#a54242]"
      {...aProps}
    >
      {props.children}
      {labelText && (
        <label className="absolute -top-1 left-[6rem] text-sm font-semibold text-[#a54242]">
          {labelText}
        </label>
      )}
    </a>
  );
}
