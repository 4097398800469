import { PropsWithChildren } from "react";

export function K33ButtonRed(
  props: { href?: string } & JSX.IntrinsicElements["button"] & PropsWithChildren
) {
  return (
    <a href={props.href}>
      <button
        onClick={props.onClick}
        className={
          props.className +
          "flex h-[3.375rem] items-center rounded-[4px] text-base bg-[#a54242] py-3 px-10 text-center font-manrope font-semibold text-white transition-colors duration-300 ease-in-out hover:bg-[#803333] focus:bg-[#803333]"
        }
      >
        {props.children}
      </button>
    </a>
  );
}
