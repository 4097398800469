export type NavigationMode = "OPEN" | "ADMIN" | "USER";

export type NavigationConfig = Record<
  string,
  { name: string; href: string; labelText?: string }[] | { href: string }
>;

export const openNavBarNavigationConfig: NavigationConfig = {
  Products: [
    {
      name: "Trading",
      href: "https://k33.com/markets",
    },
    {
      name: "Custody",
      href: "https://k33.com/custody",
    },
    {
      name: "Fund",
      href: "https://k33.com/fund",
    },
    {
      name: "Research",
      href: "https://k33.com/info-research",
    },
    {
      name: "Lending",
      href: "/",
      labelText: "soon",
    },
  ],
  Research: {
    href: "https://k33.com/info-research",
  },
  Company: [
    {
      name: "About us",
      href: "https://k33.com/company",
    },
    {
      name: "Updates",
      href: "https://k33.com/company-news",
    },
    {
      name: "Blog",
      href: "https://k33.com/blog",
    },
  ],
};

export const openDropdownNavigationConfig: NavigationConfig = {
  Products: openNavBarNavigationConfig.Products,
  Company: openNavBarNavigationConfig.Company,
};

export const userNavBarNavigationConfig: NavigationConfig = {
  Loan: { href: "/loan" },
  Wallet: { href: "/wallet" },
  Help: { href: "/help" },
};

export const userDropdownNavigationConfig: NavigationConfig = {
  Menu: [
    { name: "Loan", href: "/loan" },
    { name: "Wallet", href: "/wallet" },
    { name: "Help", href: "/help" },
  ],
};

export const adminNavBarNavigationConfig: NavigationConfig = {
  Home: { href: "/admin" },
  Loans: { href: "/admin/loans" },
  Users: { href: "/admin/users" },
};

export const adminDropdownNavigationConfig: NavigationConfig = {
  Menu: [
    { name: "Home", href: "/admin" },
    { name: "Loans", href: "/admin/loans" },
    { name: "Users", href: "/admin/users" },
  ],
};
