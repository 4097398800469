"use client";

import { useState } from "react";
import Image from "next/image";
import { HamburgerMenuButton } from "./HamburgerMenuButton";
import { K33DropdownMenu } from "./K33DropdownMenu";
import { K33NavBarMenu } from "./K33NavBarMenu";

export function K33Navigation() {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  return (
    <div
      id="nav-menu-container"
      className="relative flex w-full flex-col items-center justify-center bg-[#1e1e24] py-6"
    >
      <div
        id="nav-menu-padding"
        className="flex w-full max-w-5xl flex-row items-center justify-between px-4 sm:px-7 lg:max-w-6xl lg:justify-start xl:px-0"
      >
        <a href={"https://k33.com"}>
          <Image
            alt="K33 logo"
            width={120}
            height={49}
            src={"/images/logo/K33_Red.svg"}
          />
        </a>
        <K33NavBarMenu />
        <HamburgerMenuButton
          menuOpen={sideBarOpen}
          setMenuOpen={(value) => setSideBarOpen(value)}
        />
      </div>
      <K33DropdownMenu menuOpen={sideBarOpen} />
    </div>
  );
}
